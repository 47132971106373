import * as THREE from 'three';
import Object from './Object';
import Plane from './Plane';

export default class Canvas {
  constructor () {
    this.time = 0;
    this.isHover = false;

    this.setLinkHover();

    this.init();

    this.import();

    this.light();
  }

  light () {
    this.Rectlight = new THREE.RectAreaLight( 0xffffff, 10, 50, 250 );
    this.Rectlight.position.set( 0, 100, 150);
    this.scene.add(this.Rectlight);
  }

  async import () {
    this.Object = new Object;

    const objMesh = await this.Object.init();

    this.scene.add(objMesh);

    this.Plane = new Plane(this.renderer, this.camera, this.scene);

    const plane = this.Plane.init();

    this.scene.add(plane);
  }

  init () {
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setSize(this.width, this.height);
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    const container = document.querySelector('.container');
    container.appendChild( this.renderer.domElement );
    //container.style.zIndex = -1;

    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera( 60, this.width/this.height, 10, 3000);
    this.scene.add( this.camera );

    this.resize();
    this.raf();

    window.addEventListener('resize', () => this.resize(), {passive: true});
  }

  resize () {
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    if( this.width < 540 && this.width === windowWidth/2) return 

    let width, height;
    if( this.isMobile ) {
      width = windowWidth/2
      height = windowHeight/2
    }else {
      width = windowWidth
      height = windowHeight
    }

    this.width = width;
    this.height = height;

    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize(width, height)

    this.camera.position.z = -this.height / 2 / Math.tan(Math.PI / 6);
    // this.camera.position.z = - 4e5 / this.width ;

    this.camera.lookAt(0,0,0);
    this.camera.aspect = width / height;
	  this.camera.updateProjectionMatrix();

    this.renderer.domElement.setAttribute('style', `width: ${windowWidth}px;height: ${windowHeight}px;`);

    this.renderer.render( this.scene, this.camera );
  }

  raf () {
    let plus = 0.008;
    plus *= this.isHover ? 3 : 1;
    this.time += plus;
    if( this.Object ) {
      this.Object.raf(this.time);
    }
    if( this.Plane ) {
      this.Plane.raf(this.time);
    }

    this.renderer.render( this.scene, this.camera );
    
    window.requestAnimationFrame( this.raf.bind(this) );
  }

  setLinkHover () {
    const links = document.querySelectorAll('.jsForHoverLink');

    links.forEach(link => {
      link.addEventListener('mouseover', () => {
        this.isHover = true;
        this.Rectlight.intensity = 20;
      });
      link.addEventListener('mouseout', () => {
        this.isHover = false;
        this.Rectlight.intensity = 10;
      });
    });
  }
}