import * as THREE from 'three';

import zatyIcon from '../../asset/zaty.svg';

import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js';

export default class Object {
  constructor() {
    this.group = new THREE.Group();
  }

  async loadSvgGeometry () {
    const loader = new SVGLoader();
    const data = await loader.loadAsync(zatyIcon);

    const extrudeSettings = {
      steps: 1,
      depth: 30,
      bevelEnabled: false
    }

    const pathsGeo = [];
    data.paths.forEach( path => {
      const shape = path.toShapes();
      const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
      geometry.scale(1, -1, 1);
      
      pathsGeo.push( geometry );
    } );

    return BufferGeometryUtils.mergeBufferGeometries(pathsGeo);
  
  }

  async init () {
    const geometry = await this.loadSvgGeometry();
    geometry.center();

    const material = new THREE.MeshStandardMaterial({
      color    : 0x333333,
      roughness: 0,
      metalness: .5,
      side     : THREE.BackSide
    });


    this.meshGroup = new THREE.Group();
    this.mesh = new THREE.Mesh(geometry, material);
    this.meshGroup.add(this.mesh);

    this.group.add(this.meshGroup);

    return this.group;
  }

  

  raf (time) {
    if( !this.mesh ) return;

    this.meshGroup.position.y = Math.sin(time) * 20;

    this.meshGroup.rotation.y = -time;

    this.mesh.rotation.x = Math.sin(time * 2.) * .21;
    this.mesh.rotation.z = Math.cos(time * 1.4) * .17;
  }
}