import Canvas from "./inc/Canvas";

class Init {
  constructor() {
    new Canvas;
    this.linkHover();
  }
  linkHover () {
    const linkColumnFirst = document.querySelectorAll('.jsLinkColumnFirst');

    linkColumnFirst.forEach( column => {

      const hoverLink = column.querySelector('.link-area__link');
      const targetLink = column.parentElement.querySelector('.jsLinkColumnFirst + .link-area__column .link-area__line');

      hoverLink.addEventListener('mouseover', () => {
        targetLink.classList.add('link-area__line--hovered')
      });
      hoverLink.addEventListener('mouseout', () => {
        targetLink.classList.remove('link-area__line--hovered')
      });
    });
  }
}

window.addEventListener('DOMContentLoaded', () => {
  new Init();
});