import * as THREE from 'three';
import MeshReflectorMaterial from './MeshReflectorMaterial';

export default class Plane {
  constructor (renderer, camera, scene) {
    this.renderer = renderer;
    this.scene = scene;
    this.camera = camera;

    this.cameraSetting();
  }

  cameraSetting () {

  }

  init () {

    const geometry = new THREE.PlaneGeometry( 15000, 15000 );

    this.plane = new THREE.Mesh(geometry);

    this.plane.material = new MeshReflectorMaterial(this.renderer, this.camera, this.scene, this.plane, {
      resolution: 1024,
      mixBlur: -.5,
      mixContrast: 1.,
      mirror: .9,
      depthScale: 0,
    });

    this.plane.rotation.x = Math.PI * -.5;
    this.plane.position.y = -170;

    return this.plane;
  }

  raf () {
    this.plane.material.update();
  }
}